import { polyfillIntl } from 'polyfills.js';
import { parse, stringify } from 'query-string';
import { Suspense, lazy } from 'react';
import { IntlProvider } from 'react-intl';

import AppErrors from 'AppErrors';
import errorLog from 'AppErrors/errorLog';
import { WrappedSpinner } from 'components/Spinner';
import { getAuthFromQuipToken } from 'data/getAuthFromQuipToken';
import { getAuthToken } from 'utils/storage/storageHelpers.js';

const messages = {};

export const sanitizeUrlParams = () => {
  const params = parse(window.location.search, { arrayFormat: 'bracket' });
  const newSearchParams = { ...params };
  delete newSearchParams.quip_user_token;
  delete newSearchParams.ci;
  delete newSearchParams.redirectTo;
  const newSearchQuery = stringify(newSearchParams, { arrayFormat: 'bracket' });
  const newLocation = `${window.location.href.split('?')[0]}${
    newSearchQuery.length ? `?${newSearchQuery}` : ''
  }`;

  window.history.replaceState(null, null, newLocation);
};

export const getQuipAuthPromise = async () => {
  const params = parse(window.location.search, { arrayFormat: 'bracket' });

  if (params.quip_user_token && !getAuthToken()) {
    return getAuthFromQuipToken(params.quip_user_token);
  }
  return null;
};

/*
We get the user authentication and initialise LD and Rollbar here all at the same time
 */
const InitialiseAuthentication = lazy(async () => {
  try {
    const [App, LDProvider, ErrorProvider, auth] = await Promise.all([
      import('../App/index.js').then(({ default: App }) => App),

      import('launchdarkly-react-client-sdk').then(({ asyncWithLDProvider }) =>
        asyncWithLDProvider({
          clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
          options: {
            bootstrap: 'localStorage',
          },
        }),
      ),

      import('root/ErrorProvider').then(
        ({ default: ErrorProvider }) => ErrorProvider,
      ),

      getQuipAuthPromise()
        .then(() => ({ error: null, isAuthenticated: !!getAuthToken() }))
        .catch((e) => {
          errorLog(AppErrors.quipUserTokenError, e);
          return { error: e };
        })
        .finally(() => sanitizeUrlParams()),

      polyfillIntl(),
    ]);

    return {
      default: () => (
        <IntlProvider defaultLocale="en" locale="en" messages={messages}>
          <ErrorProvider>
            <LDProvider>
              <App authentication={auth} />
            </LDProvider>
          </ErrorProvider>
        </IntlProvider>
      ),
    };
  } catch (e) {
    errorLog(AppErrors.appInitialisationError, e);
    return {
      // TODO: Error page without Intl
      default: () => (
        <>
          <h1>Error</h1>
          <p>Something has gone wrong</p>
        </>
      ),
    };
  }
});

const Initialisation = (props) => {
  return (
    <Suspense fallback={<WrappedSpinner absolute />}>
      <InitialiseAuthentication {...props} />
    </Suspense>
  );
};

export default Initialisation;
