import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom/client';
import Initialisation from 'root/Initialisation';
// import reportWebVitals from './internals/reportWebVitals';
import './onload';
import './wdyr';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Initialisation />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log data (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
