import isDevEnv from 'utils/isDevEnv';

export const getErrorTitle = (error) =>
  error ? `[${error.code}] - ${error.short}` : 'Error';

const errorLog = (error, ...rest) => {
  const args = [getErrorTitle(error), error, ...rest];

  console.info(args);
  // stack
  if (isDevEnv && rest[0]) {
    console.error(rest[0]);
  }
  if (!isDevEnv) {
    window.Rollbar?.error.apply(window.Rollbar, args);
  }
  return error;
};

export default errorLog;
