import AppErrors from 'AppErrors';
import errorLog from 'AppErrors/errorLog';
import request from 'utils/request';
import {
  removeAuthError,
  setAuthError,
  setAuthToken,
} from 'utils/storage/storageHelpers';

export const getAuthFromQuipToken = async (quipToken) => {
  try {
    const response = await request('v2/auth', {
      body: JSON.stringify({ auth_type: 'quip_member', message: quipToken }),
      method: 'POST',
    });
    removeAuthError();
    return setAuthToken(response.auth_token, response.ttl * 1000);
  } catch (e) {
    errorLog(AppErrors.errorAuthenticatingUserWithQuipToken, e);
    setAuthError(e);
    return await Promise.reject(e);
  }
};
