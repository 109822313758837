import { defaultStorage } from './defaultStorage';
import { UserCache } from './UserCache';

const tokenKey = 'auth';
const memberKey = 'member';
const lastSearchKey = 'last-search';
const errorKey = 'tp-auth-error';

/**
 * Set user auth token in localstorage
 * @param token
 * @param ttl
 */
export function setAuthToken(token, ttl) {
  const _token = `${token}::${Date.now() + ttl}`;
  defaultStorage.setItem(tokenKey, _token, ttl);
}

/**
 * Remove user auth token from localstorage
 */
export const removeAuthToken = () => {
  defaultStorage.removeItem(tokenKey);
};

/**
 * Get user auth token from localstorage
 * @return {string|null}
 */
export const getAuthToken = () => {
  try {
    const [token, t] = defaultStorage.getItem(tokenKey).split('::');
    if (t && parseInt(t) < Date.now()) {
      removeAuthToken();
      return null;
    }
    return token;
  } catch (e) {
    return null;
  }
};

/**
 * Set user member in localstorage
 * @param member
 */
export const setMember = (member) => UserCache.setItem(memberKey, member);

/**
 * Remove member from localstorage
 */
export const removeMember = () => UserCache.removeItem(memberKey);

/**
 * Get user member from localstorage
 * @return {string|null}
 */
export const getMember = () => UserCache.getItem(memberKey);

export const getLastSearch = () => {
  return UserCache.getItem(lastSearchKey);
};

export const setLastSearch = (val) => {
  UserCache.setItem(lastSearchKey, val);
};
export const removeLastSearch = () => UserCache.removeItem(lastSearchKey);

// set auth error
export const setAuthError = (error) => {
  UserCache.setItem(errorKey, error);
};

// get auth error
export const getAuthError = () => {
  return UserCache.getItem(errorKey);
};

// remove auth error
export const removeAuthError = () => {
  UserCache.removeItem(errorKey);
};
