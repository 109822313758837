const isDevEnv = () => {
  if (process.env.NODE_ENV === 'test') return false;
  return (
    window.location.href.indexOf('localhost') > -1 ||
    window.location.href.indexOf('ngrok') > -1 ||
    process.env.NODE_ENV === 'development'
  );
};

export default isDevEnv();
