import AppErrors from 'AppErrors';
import errorLog from 'AppErrors/errorLog';
import { getAuthToken } from 'utils/storage/storageHelpers';

/**
 * Request with fetch
 * @param url
 * @param options
 * @return {Promise<any>}
 */
export default async function request(
  url,
  options = {
    method: 'GET',
    headers: new Headers(),
  },
) {
  const fullURL = `${process.env.REACT_APP_TP_API_URL}/${url}`;
  if (!options.headers) {
    options.headers = new Headers();
  }
  options.headers.set('X-Platform', 'member');
  options.headers.set('X-Api-Key', process.env.REACT_APP_TP_API_KEY);
  const authToken = getAuthToken();
  if (authToken) {
    options.headers.set('x-auth-token', authToken);
  }

  if (options.method !== 'DELETE') {
    options.headers.set('Content-Type', 'application/json');
  }

  const ret = await window.fetch(fullURL, options);
  const status = ret.status;
  const jsonResponse = await ret.json();

  if (jsonResponse.errors) {
    const err = new Error(JSON.stringify(jsonResponse.errors[0]));
    // add data to error object so we can say "error.external_id" or whatever
    for (let i in jsonResponse.errors[0]) {
      err[i] = jsonResponse.errors[0][i];
    }
    errorLog(
      AppErrors.requestError,
      jsonResponse.errors[0],
      { response: jsonResponse, url: fullURL, status, body: options.body },
      err,
    );
    throw err;
  }
  return jsonResponse;
}
