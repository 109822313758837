/*
 * App Error Configuration
 *
 * ***IMPORTANT***
 * 1. Once an error is in here, you must not change the error code or swap for another one
 * 2. Each {Nxxx} group should correspond to a <RollbarContext/> (see Router/index.test.js)
 * ***************
 *
 * Error groups:
 *  * 1xxx
 *    - App errors - generic - uncaught errors, initialisation errors, request error
 *  * 2xxx
 *    - Auth errors - quip handover, etc
 *  * 3xxx
 *    - Search errors
 *  * 4xxx
 *    - Provider errors
 *  * 5xxx
 *    - Practice errors
 *  * 6xxx
 *    - Appointment errors
 *
 *
 * Errors include an external error to display to the user.
 * You can use the following error shorthand codes for generic errors:
 *
 *  -1 Do not show to user (should bubble up)
 *  1 (Uncaught exception) - Oops! Something has gone wrong
 *  2 (401/403) - You are not authorized to perform that action
 *  3 (404) - Oops! We couldn't find what you were looking for. Try a new search
 *
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  testError: {
    code: '0001',
    short: 'Test error',
    description: 'Test error for testing purposes',
    external: 1,
  },
  eventNotFound: {
    code: '0002',
    short: 'Event not found',
    description: 'This event could not be found',
    external: -1,
  },
  // 1xxx
  uncaughtException: {
    code: '1000',
    short: 'Uncaught exception',
    description: 'Uncaught exception in app. Caught by Generic error catch',
    external: 1,
  },
  appInitialisationError: {
    code: '1001',
    short: 'App initialisation error',
    description: 'Could not initialise app',
    external: 1,
  },
  requestError: {
    code: '1002',
    short: 'Request error',
    description: 'There was an error with an API request (status > 400)',
    external: -1,
  },
  attemptedScriptInjection: {
    code: '1003',
    short: 'Attempted script injection',
    description: 'Attempted script injection',
    external: -1,
  },
  // 2xxx
  errorAuthenticatingUserWithQuipToken: {
    code: '2001',
    short: 'Error authenticating user with quip token',
    description: 'Could not get authentication with quip_user_token',
    external: 2,
  },
  errorGettingMemberWithAuthToken: {
    code: '2002',
    short: 'Error getting member with auth token',
    description:
      'Could not perform GET on v3/members with auth token in session storage',
    external: 2,
  },
  // 3xxx
  cannotFindPracticesMatchingSearch: {
    code: '3001',
    short: 'Cannot find practices matching search',
    description: 'Cannot find practices matching search.',
    external: 3,
  },
  contextSearchError: {
    code: '3002',
    short: 'Context search error',
    description: 'Error performing context search',
  },
  mapSearchError: {
    code: '3003',
    short: 'Map search error',
    description: 'Error when searching via map update',
  },
  geolocationError: {
    code: '3004',
    short: 'Geolocation error',
    description: 'Error with geolocation provider',
  },
  // 4xxx
  cannotFindProviderSlug: {
    code: '4001',
    short: 'Cannot find provider with given slug',
    description: 'Cannot find provider with given slug',
    external: 3,
  },
  errorFetchingProvider: {
    code: '4002',
    short: 'Error fetching provider',
    description: 'Could not get provider with UUID or slug',
    external: 3,
  },
  cannotFindProviderId: {
    code: '4003',
    short: 'Cannot find provider with given slug',
    description: 'Cannot find provider with given slug',
    external: 3,
  },
  // 5xxx
  cannotFindPracticeSlug: {
    code: '5001',
    short: 'Cannot find provider with given slug',
    description: 'Cannot find provider with given slug',
    external: 3,
  },
  errorFetchingPractice: {
    code: '5002',
    short: 'Error fetching practice',
    description: 'Could not get practice with UUID or slug',
    external: 3,
  },
  errorGettingPlacesData: {
    code: '5003',
    short: 'Error getting places data',
    description: 'Error retrieving places data from google maps places service',
    external: 3,
  },
  tooManyPlaces: {
    code: '5004',
    short: 'Too many places',
    description:
      'The google places API returned too many results from the API request - not using any data',
    external: -1,
  },
  cannotFindPracticeId: {
    code: '5005',
    short: 'Cannot find provider with given id',
    description: 'Cannot find provider with given id',
    external: 3,
  },
  cannotFindPracticeDirectories: {
    code: '5006',
    short: 'Cannot find provider directory from practice',
    description: 'Cannot find provider directory from practice',
    external: 3,
  },
  errorFetchingProviders: {
    code: '5007',
    short: 'Error fetching providers',
    description: 'Could not get providers for practice',
    external: 3,
  },
  // 6xxx
  errorCreatingAppointment: {
    code: '6001',
    short: 'Error creating appointment',
    description:
      'There was an error creating the appointment, either with the patient or the booking appointment.',
    external: 1,
  },
  errorCreatingEmailAppointment: {
    code: '6011',
    short: 'Error creating email appointment request',
    description:
      'There was an error creating the appointment request, either with the patient or the booking appointment.',
    external: 1,
  },
  appointmentNotFound: {
    code: '6002',
    short: 'Appointment not found',
    description: 'Could not find appointment with this id',
    external: 3,
  },
  appointmentProviderNotFound: {
    code: '6003',
    short: 'Appointment provider not found',
    description: 'Could not find the provider related to this appointment',
    external: 3,
  },
  unauthorizedToViewAppointment: {
    code: '6004',
    short: 'Not authorized to view appointment',
    description: 'Could not access this appointment id with this user',
    external: 2,
  },
  noAppointmentId: {
    code: '6005',
    short: 'No appointment id',
    description: 'User navigated to the /appointment route with no id',
    external: -1,
  },
  appointmentOptionsError: {
    code: '6006',
    short: 'Appointment options error',
    description: 'Error retrieving appointment options',
    external: -1,
  },
  appointmentTypesError: {
    code: '6007',
    short: 'Appointment types error',
    description: 'Error retrieving appointment types',
    external: -1,
  },
  // 9xxx
  storageErrorGetStorage: {
    code: '9001',
    short: 'Storage error',
    description: 'Error parsing getStorage',
    external: -1,
  },
};
