class MemoryStorage {
  data = {};

  constructor(props = {}) {
    this.cacheTime = props.cacheTime ?? -1;
  }

  setItem(key, data) {
    this.data[key] = { data, _t: this.cacheTime > -1 ? Date.now() : -1 };
  }

  getItem(key) {
    let d = this.data[key];
    if (d && (d._t === -1 || Date.now() - d._t < this.cacheTime)) {
      return d.data;
    }
    return null;
  }

  clear() {
    this.data = {};
  }
}

export default MemoryStorage;
