import querystring from 'query-string';
import isDevEnv from './isDevEnv';
import { defaultStorage } from './storage/defaultStorage';

export const isGlobalOptionSet = (key) => {
  const k = defaultStorage.getItem(key);
  return k === 'true' || k === true;
};

export const setGlobalOption = (key, value) => {
  defaultStorage.setItem(key, value, -1);
};

export const checkGlobalOptionUrl = (key) => {
  const qs = querystring.parse(window.location.search);

  const option = qs[key] || isGlobalOptionSet(key);

  setGlobalOption(
    key,
    option === 'true' || option === true || option === '1' || option === 1,
  );

  if (qs[key]) {
    delete qs[key];
    window.location.search = querystring.stringify(qs, {
      arrayFormat: 'bracket',
    });
    return option;
  }
};

export const GLOBAL_OPTION_EMBED = 'embed';
export const GLOBAL_OPTION_TEST_PRACTICES = 'test_practices';
export const GLOBAL_OPTION_DEBUG_APPOINTMENT_ID = 'debug_appointment_id';
export const GLOBAL_OPTION_MOCK_PLACES = 'mock_places';

export const isEmbedded = () => {
  return isGlobalOptionSet(GLOBAL_OPTION_EMBED);
};

export const isTestPractices = () => {
  return isGlobalOptionSet(GLOBAL_OPTION_TEST_PRACTICES);
};

export const isDebugAppointmentId = () => {
  return isGlobalOptionSet(GLOBAL_OPTION_DEBUG_APPOINTMENT_ID);
};

export const isMockPlaces = () => {
  return isGlobalOptionSet(GLOBAL_OPTION_MOCK_PLACES);
};

export const startupOptions = [
  GLOBAL_OPTION_EMBED,
  GLOBAL_OPTION_TEST_PRACTICES,
  GLOBAL_OPTION_DEBUG_APPOINTMENT_ID,
  GLOBAL_OPTION_MOCK_PLACES,
];

export const checkStartupOptions = () => {
  startupOptions.forEach((option) => {
    checkGlobalOptionUrl(option);
  });
  if (isDevEnv) {
    setGlobalOption(GLOBAL_OPTION_MOCK_PLACES, true);
  }
};
