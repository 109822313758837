const scrubUrlQueryParams = ({ payload, next }) => {
  let _payload = { ...payload };
  _payload.obj.context.page.referrer =
    payload.obj.context.page.referrer.split('?')[0];
  _payload.obj.context.page.url = payload.obj.context.page.url.split('?')[0];
  _payload.obj.context.page.search = '***';
  _payload.obj.properties.referrer =
    payload.obj.context.page.referrer.split('?')[0];
  _payload.obj.properties.url = payload.obj.context.page.url.split('?')[0];
  _payload.obj.properties.search = '***';
  next(_payload);
};
export default scrubUrlQueryParams;
