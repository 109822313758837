import AppErrors from 'AppErrors';
import errorLog from 'AppErrors/errorLog';
import MemoryStorage from './memoryStorage';

console.info('App version', process.env.REACT_APP_APP_VERSION);
export const DEFAULT_CACHE_TIME = 1000 * 60 * 2;

export const DEFAULT_STORE = 'quip::store';

export class QuipStorage {
  constructor(
    key = DEFAULT_STORE,
    type = 'session',
    version = process.env.REACT_APP_APP_VERSION,
    cacheTime = DEFAULT_CACHE_TIME,
  ) {
    this.key = key;
    this.type = type;
    this.version = version;
    this.flag = true;
    this.cacheTime = cacheTime;
    switch (type) {
      case 'session':
        if (!window.sessionStorage) {
          this.storage = new MemoryStorage({ cacheTime });
        } else this.storage = window.sessionStorage;
        break;
      case 'local':
        if (!window.localStorage) {
          this.storage = new MemoryStorage({ cacheTime });
        } else this.storage = window.localStorage;
        break;
      default:
        this.storage = new MemoryStorage({ cacheTime });
    }

    this.init(key);
  }

  setDefaultStorageData() {
    const data = {
      version: this.version,
      data: {},
    };
    this.storage.setItem(this.key, JSON.stringify(data));
    return data;
  }

  init(key, flag = true) {
    this.flag = flag;
    try {
      const storageData = JSON.parse(this.storage.getItem(key));
      if (!storageData || storageData.version !== this.version) {
        return this.setDefaultStorageData();
      }
      return storageData;
    } catch (e) {
      return this.setDefaultStorageData();
    }
  }

  toggleCache(flag, cacheTime = DEFAULT_CACHE_TIME) {
    this.flag = flag;
    this.cacheTime = cacheTime;
  }

  getStorage() {
    try {
      return JSON.parse(this.storage.getItem(this.key));
    } catch (e) {
      errorLog(AppErrors.storageErrorGetStorage);
      return this.init(this.key, this.type);
    }
  }

  getItem(key) {
    try {
      const storageData = this.getStorage()?.data;
      const item = storageData?.[key];
      if (
        this.flag &&
        item?.exp &&
        (item.exp === -1 || Date.now() < item.exp)
      ) {
        return storageData?.[key]?.data || null;
      } else {
        return null;
      }
    } catch (e) {
      this.init(this.key, this.type);
      return null;
    }
  }

  setItem(key, data, cacheTime = this.cacheTime) {
    let storageData;
    if (!this.flag) {
      return data;
    }
    try {
      storageData = this.getStorage();
    } catch (e) {
      storageData = this.init(this.key, this.type);
    }
    try {
      storageData.data[key] = {
        data,
        _t: Date.now(),
        exp: cacheTime > -1 ? Date.now() + cacheTime : -1,
      };
      this.storage.setItem(this.key, JSON.stringify(storageData));
      return data;
    } catch (e) {
      console.error('Could not set data', e);
      return null;
    }
  }

  removeItem(key) {
    let storageData = this.getStorage();
    if (!storageData) {
      storageData = this.init(this.key, this.type);
    }
    delete storageData.data[key];
    this.storage.setItem(this.key, JSON.stringify(storageData));
  }

  clear() {
    this.setDefaultStorageData();
  }
}
