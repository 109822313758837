import { checkStartupOptions } from 'utils/globalUrlOption';
import segment from './external/segment';

checkStartupOptions();

segment();

// cypress testing waits for this value
if (window.Cypress) {
  window.appReady = true;
}
