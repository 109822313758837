import { shouldPolyfill as cl } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as l } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as pr } from '@formatjs/intl-pluralrules/should-polyfill';

if (![].flat) {
  import('array-flat-polyfill');
}

export async function polyfillIntl() {
  if (cl()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
  if (l()) {
    await import('@formatjs/intl-locale/polyfill');
  }
  if (pr('en')) {
    await import('@formatjs/intl-pluralrules/polyfill-force');
    await import('@formatjs/intl-pluralrules/locale-data/en');
  }
}
