import styled, { css, keyframes } from 'styled-components';

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const sizes = {
  xs: 12,
  sm: 24,
  md: 32,
  lg: 56,
};

const thicknesses = {
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 5000;
  ${({ absolute }) =>
    absolute &&
    css`
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(2px);
    `};
`;

const Spin = styled.span`
  display: inline-block;

  &,
  &:after {
    border-radius: 50%;
    width: ${({ size }) => sizes[size]}px;
    height: ${({ size }) => sizes[size]}px;
  }

  margin: auto;
  position: relative;
  border-top: ${({ thickness, size }) => thickness || thicknesses[size]}px solid
    rgba(255, 255, 255, 0.2);
  border-right: ${({ thickness, size }) => thickness || thicknesses[size]}px
    solid rgba(255, 255, 255, 0.2);
  border-bottom: ${({ thickness, size }) => thickness || thicknesses[size]}px
    solid rgba(255, 255, 255, 0.2);
  border-left: ${({ thickness, size }) => thickness || thicknesses[size]}px
    solid ${({ color = 'black' }) => color};
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;
`;

const Spinner = ({
  size = 'sm',
  thickness,
  color = 'black',
  className,
  ...props
}) => {
  return (
    <Spin
      {...props}
      className={className}
      color={color}
      size={size}
      thickness={thickness}
    />
  );
};

export const WrappedSpinner = ({ absolute }) => {
  return (
    <Wrapper absolute={absolute} data-testid="spinner">
      <Spinner />
    </Wrapper>
  );
};

export default Spinner;
